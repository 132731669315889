import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import RequireAuth from './components/RequireAuth';
import Home from './components/Home';
import Support from './components/Support';
import Login from './components/Login';
import AppView from './components/appview/AppView';
import TopAppBar from './components/TopAppBar';
import ChangePassword from './components/ChangePassword';
import { ProvenanceViewer } from './components/appview/ProvenanceViewer';
import { ErrorBox } from './components/ErrorBox';

import { Amplify } from '@aws-amplify/core';
import { Authenticator } from '@aws-amplify/ui-react';

import { AUTH_CONFIG } from './auth_config';
import UserProfile from './components/UserProfile';
// import { Alert, AlertTitle, Box, Container, Stack, Typography} from '@mui/material';
import { Box } from '@mui/material';
// import SentimentVeryDissatisfied from '@mui/icons-material/SentimentVeryDissatisfied';

Amplify.configure(AUTH_CONFIG)

// export function Home() {
//   return (
//     <Container>
//       <h1>This is the homepage</h1>
//     </Container>
//   )
// }

// export function NotFound() {
//   return (
//     <Stack alignItems="center" justifyContent="center" height="100%">
//         <Alert icon={<SentimentVeryDissatisfied fontSize="large" />} severity='error' fontSize="large">
//             <AlertTitle fontSize="large"><strong>Oops!</strong></AlertTitle>                
//             <Typography fontSize="large">
//               Something went wrong. The web address you were trying to reach doesn't exist. 
//             </Typography> 
//         </Alert>
//     </Stack> 
//   )
// }

export function App() {
  document.title = 'deepKPI by revelata | beta'
  return (
    <>
      <Authenticator.Provider>
        <Router>
          <TopAppBar></TopAppBar>
          <Box sx={{ height: "calc(100vh - 85px)", minHeight: "calc(100vh - 85px)"}}> {/* 85px is the height of TopAppBar */}
            <ErrorBoundary FallbackComponent={ErrorBox}>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/support" element={<Support />}/>
              <Route path="/login" element={<Login tab="signIn"/>}/>
              <Route path="/signup" element={<Login tab="signUp"/>}/>
              <Route path="/docviewer" element={<ProvenanceViewer/>}/>
              <Route path="/app" element={ <RequireAuth><AppView/></RequireAuth> }/>
              {/* <Route path="/app/:ticker" element={ <RequireAuth><AppView/></RequireAuth> }/>
              <Route path="/app/:ticker/:formType" element={ <RequireAuth><AppView/></RequireAuth> }/>
              <Route path="/app/:ticker/:formType/:fromDate" element={ <RequireAuth><AppView/></RequireAuth> }/>
              <Route path="/app/:ticker/:formType/:fromDate/:toDate" element={ <RequireAuth><AppView/></RequireAuth> }/> */}
              <Route path="/changepassword" element={
                <RequireAuth><ChangePassword/></RequireAuth>
              }/>
              <Route path="/profile" element={
                <RequireAuth><UserProfile/></RequireAuth>
              }/>
              <Route path="*" element={<Home/>}/>
            </Routes>
            </ErrorBoundary>
          </Box>
        </Router>              
      </Authenticator.Provider>
    </>      
  );
}

export default App;