import './../../App.css';
import { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';

import { Auth } from 'aws-amplify';

import { 
    Alert, Box, Button, ButtonGroup, Container, Dialog, DialogTitle, DialogContentText, Grow,
    IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText, 
    Paper, Popper, Stack, TextField, Typography, ClickAwayListener, ListItemIcon, Tooltip, 
    ToggleButtonGroup, ToggleButton
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import LabelIcon from '@mui/icons-material/Label';
import DownloadIcon from '@mui/icons-material/Download';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { API_URL, makePostRequest } from '../RestApi';
import { MIN_TS_LEN, MAX_OTHER_SECTION_LEN } from './constants';
import { ChartList } from './ChartList';
import { 
    SearchBarContext, DisplayedCikContext, SelectedTimeSeriesContext, 
    DateRangeContext, ChartExpandStateContext, SelectedDatapointContext, OnboardingWizardContext 
} from './Contexts';
import { exportXlsxWorkbook } from './XlsxUtils';
import { decodeTimeSeriesApiResponse } from './TimeSeriesApi';

import { ampli } from '../../ampli/index.ts';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { toDate as datefnstz_toDate } from 'date-fns-tz';
import { OnboardingWizard, advanceWizardState } from './Onboarding.js';


function truncString(s, maxLength) {    
    if (s.length > maxLength) {
        let trunc = s.substr(0, maxLength);
        trunc= trunc.substr(0, Math.min(trunc.length, trunc.lastIndexOf(" ")));
        trunc += '...';
        return trunc
    }

    return s;
}

function getDataArrayWithSectionIdx(data) {
    // Check if data is an array
    if (!Array.isArray(data)) {
        return [];
    }

    return data.flatMap((obj) => {
        // Check if data is an array
        if (Array.isArray(obj.ts)) {
            return obj.ts.map((item) => (
                {
                    ...item,
                    sectionIdx: obj.sectionIdx,
                }));
        } else {
            // If not an array, assume it's a single object
            return [{ ...obj, sectionIdx: obj.sectionIdx }];
        }
    });
}

const sortBy = (data, key) => {
    return data.sort((a, b) => {
        const textA = a[key].toLowerCase();
        const textB = b[key].toLowerCase();
        return textA.localeCompare(textB);
    });
};

const sortBySeriesLength = (data) => {
    return data.sort((a, b) => {
        // Filter timeseries entries where canDisplay AND isInDateRange are true
        const filteredSeriesA = a.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        const filteredSeriesB = b.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        // Compare the length of filtered series arrays
        return filteredSeriesB.length - filteredSeriesA.length;
    });
};

const sortByLatestDate = (data) => {
    return data.sort((a, b) => {
        // Filter timeseries entries where canDisplay AND isInDateRange are true
        const filteredSeriesA = a.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        const filteredSeriesB = b.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        // Check if either series is empty (no valid entries)
        if (!filteredSeriesA.length && !filteredSeriesB.length) {
            return 0; // No dates to compare, return equal
        } else if (!filteredSeriesA.length) {
            return 1; // Series A has no valid entries, sort it lower
        } else if (!filteredSeriesB.length) {
            return -1; // Series B has no valid entries, sort it lower
        }
    
        // Extract and compare the latest displayDatetime
        const latestDateA = new Date(
            filteredSeriesA[filteredSeriesA.length - 1].data.displayDatetime
        );
        const latestDateB = new Date(
            filteredSeriesB[filteredSeriesB.length - 1].data.displayDatetime
        );
        return latestDateB.getTime() - latestDateA.getTime(); // Descending order (latest first)
    });
};

const sortByLatestFilingDate = (data) => {
    return data.sort((a, b) => {
        // Filter timeseries entries where canDisplay AND isInDateRange are true
        const filteredSeriesA = a.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        const filteredSeriesB = b.series.filter((item) => item.data.canDisplay && item.data.isInDateRange);
        // Check if either series is empty (no valid entries)
        if (!filteredSeriesA.length && !filteredSeriesB.length) {
            return 0; // No dates to compare, return equal
        } else if (!filteredSeriesA.length) {
            return 1; // Series A has no valid entries, sort it lower
        } else if (!filteredSeriesB.length) {
            return -1; // Series B has no valid entries, sort it lower
        }
    
        // Extract and compare the latest filingDate
        const latestDateA = new Date(
            filteredSeriesA[filteredSeriesA.length - 1].filingDate
        );
        const latestDateB = new Date(
            filteredSeriesB[filteredSeriesB.length - 1].filingDate
        );
        return latestDateB.getTime() - latestDateA.getTime(); // Descending order (latest first)
    });
  };

const sortByScore = (data) => {
return data.sort(
    (a, b) => {                
        if (a.score.aggregated > b.score.aggregated) { return -1 };
        if (a.score.aggregated < b.score.aggregated) { return 1 };
        return 0;
    }
)
};


export function DataPane() {    
    const defaultSortKey = "score";    
    const defaultTimeSpan = "MAX";

    const [accessToken, setAccessToken] = useState(null);

    const [apiError, setApiError] = useState(false);
    const [data, setData] = useState(null); 
    const [sectionHeaders, setSectionHeaders] = useState(null); 
    const [companyName, setCompanyName] = useState(null); 
    const [exchangeName, setExchangeName] = useState(null);    
    const [isDataEmpty, setIsDataEmpty] = useState(true);

    const [ticker, formType] = useContext(SearchBarContext);    
    const [displayedCik, setDisplayedCik] = useContext(DisplayedCikContext);
    const [wizardState, setWizardState] = useContext(OnboardingWizardContext);

    const [selectedTimeSeries, setSelectedTimeSeries] = useState(new Set());
    const [selectedDatapoint, setSelectedDatapoint] = useContext(SelectedDatapointContext);
    const [chartExpandState, setChartExpandState] = useState(ChartExpandStateContext);

    const [filterValue, setFilterValue] = useState("");
    const [dataDisplayMask, setDataDisplayMask] = useState(new Set());  
    const [sortKey, setSortKey] = useState("");  

    const [timeSpan, setTimeSpan] = useState(defaultTimeSpan);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    // For merge export feature
    const [splitButtonOpen, setSplitButtonOpen] = useState(false);
    const splitButtonAnchorRef = useRef(null);   
    const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
    const [mergeDialogSelection, setMergeDialogSelection] = useState(null);
    const [unitsErrorDialogOpen, setUnitsErrorDialogOpen] = useState(false);


    /********************************** Callbacks *************************************/

    const maskTimeSeriesWithDateRange = useCallback((prevState) => {        
        if (prevState !== undefined && prevState !== null) {
            const previousState = [...prevState];  // must do this?
            previousState.map(
                (ts, idx) => {
                    // Mask datapoints outside of date range                    
                    ts['series'] = ts['series'].map(
                        (dp) => {                                        
                            const dpDate = new Date(dp['data']['displayDatetime']+"T00:00:00");                                        
                            // console.log("ts", idx, dpDate, fromDate <= dpDate, dpDate <= toDate);  
                            //console.log(fromDate, toDate, dpDate);

                            // null date range means show everything
                            if (fromDate === null || toDate === null) {
                                dp['data']['isInDateRange'] = true;
                            } else {
                                dp['data']['isInDateRange'] = (fromDate <= dpDate && dpDate <= toDate);
                            }
                            
                            return dp;
                        }                    
                    )    
                    // Mask time series with no displayable datapoints
                    //console.log(ts['series'].filter((dp) => {return (dp['data']['isInDateRange'])}).length, MIN_TS_LEN);
                    const displayableTs = ts['series'].filter(
                        (dp) => {
                            return (dp['data']['isInDateRange'] && dp['data']['canDisplay'])
                        }
                    );
                    ts['shouldDisplay'] = displayableTs.length >= MIN_TS_LEN;

                    //@@@ HACK: Filter out the flat time series that are likely due to model errors
                    ts['shouldDisplay'] = ts['shouldDisplay'] && !displayableTs.every(
                        (dp) => {
                            return ["1.0", "0.0", "100.0"].includes(dp['data']['displayValue']);
                        }
                    )


                    //console.log(ts);
                    return ts;                  
                }
            )
            return previousState;
        }
        return prevState;
    }, [fromDate, toDate]);


    /********************************** useEffects ************************************/

    // Runs once after initial render
    useEffect(
        () => {
            Auth.currentSession()
            .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
            .catch(error => {console.log(error);})
        },  
        []
    );

    // Runs whenever accessToken changes
    useEffect(        
        () => {
            if (ticker !== null && formType !== null) {  
                // console.log("Preparing API call...");
                const requestPayload = {
                    'ticker': ticker,
                    'form_type': formType,
                };

                if (data !== undefined || data !== null) {
                    setData(null);
                    setIsDataEmpty(true);
                    setDisplayedCik(null);
                    setSelectedTimeSeries(new Set());
                    setSelectedDatapoint(null);
                    setChartExpandState([]);
                    setFilterValue("");    
                    setDataDisplayMask(new Set()); 
                    setSortKey(""); 
                    setTimeSpan(defaultTimeSpan);
                    setSectionHeaders(null); 
                    setCompanyName(null);
                    setExchangeName(null);              
                }

                if (accessToken) {
                    makePostRequest(API_URL+'/timeseries', accessToken, requestPayload)
                    .then(response => {                         
                        let decodedPb = decodeTimeSeriesApiResponse(response['pb']);
                        // Check if there are too many timeseries without a section.
                        const isNoisySections = decodedPb['sections'].filter(
                          (item) => item.sectionIdx === -1 && item.ts.length > MAX_OTHER_SECTION_LEN
                        ).length > 0;
                        const cikInfo =  decodedPb['cikinfo'];
                        const headerInfo = decodedPb['orderedSections'];
                        decodedPb = getDataArrayWithSectionIdx(decodedPb['sections'])
                        // decodedPb.map(
                        //     (ts) => {
                        //         console.log(ts);
                        //     }
                        // )         
                        
                        setIsDataEmpty(Array.isArray(decodedPb) && decodedPb.length === 0);                        

                        // Set the chart titles here for everything else that needs it (keyword
                        // filter, chart display, export to excel)
                        // console.log("decodedPb: ", decodedPb);
                        for (let i = 0; i < decodedPb.length; i++) {
                            // If the sections are too noisy, then we default sectionIdx of all the timeseries to -1.
                            if (isNoisySections){
                              decodedPb[i]['sectionIdx'] = -1
                            }
                            // Build chart title
                            decodedPb[i]['chartTitle'] = truncString(decodedPb[i]['description'], 80) 
                            
                            if (decodedPb[i]['unit'] || decodedPb[i]['hint']) {
                                decodedPb[i]['chartTitle'] = decodedPb[i]['chartTitle'] + " (";
                            
                                if (decodedPb[i]['unit']) {
                                    decodedPb[i]['chartTitle'] = decodedPb[i]['chartTitle'] + decodedPb[i]['unit']; 
                                } 

                                if (decodedPb[i]['unit'] && decodedPb[i]['hint']) {
                                    decodedPb[i]['chartTitle'] = decodedPb[i]['chartTitle'] + " ";
                                }

                                if (decodedPb[i]["hint"]) {
                                    decodedPb[i]['chartTitle'] = decodedPb[i]['chartTitle'] + decodedPb[i]['hint'];
                                } 

                                decodedPb[i]['chartTitle'] = decodedPb[i]['chartTitle'] + ")";                            
                            }

                            decodedPb[i]['shouldDisplay'] = true;                            
                        }
                        // console.log("decodedPb with chartTitle: ", decodedPb);

                        // Mask the time series to the user's requested date range
                        setData(decodedPb);
                        setApiError(false);
                        setSortKey(defaultSortKey);
                        setSectionHeaders(headerInfo);  
                        
                        // For setting the displayedCik context
                        setDisplayedCik(cikInfo['cik']);
                        setCompanyName(cikInfo['name']);
                        setExchangeName(cikInfo['exchange']);            
                        
                        const startState = new Array(decodedPb.length).fill(false);
                        startState[0] = true; 
                        setChartExpandState(startState);
                    })
                    .catch(
                        (error) => {
                            console.log(error);
                            setApiError(true);
                        }
                    )
                }
            } else {
                console.log("Null search")
            }
        }, 
        [accessToken, ticker, formType]
    ); 


    // Run date filter on time series whenever data or fromDate or toDate change
    useEffect(
        () => {
            // console.log("fromDate", fromDate, "toDate", toDate);
            if (displayedCik) {
                // console.log("maskTimeSeriesWithDateRange useEffect");
                setData(maskTimeSeriesWithDateRange);            
            } 
        }, 
        [displayedCik, fromDate, toDate, maskTimeSeriesWithDateRange]
        // displayedCik is a proxy dependency for when a new user query causes data to be updated
        // with the results of a new API call.  We can't put data in the dependency list because
        // it's an array and the underlying comparison to trigger useEffect doesn't do a deep
        // comparison.  Even if it did, this would cause an infinite loop. 
        //
        // However, we still can't make any changes to data inside this useEffect because then React
        // would require it to be a dependency.  The only way to do this is to set data as a
        // callback.                        
    )

    useEffect(
        () => {
            const newToDate = new Date();
            const newFromDate = new Date(newToDate);

            switch (timeSpan) {
                case "10Y":
                    newFromDate.setFullYear(newFromDate.getFullYear() - 10);
                    setFromDate(newFromDate);                
                    setToDate(newToDate);
                    break;
                case "5Y":                
                    newFromDate.setFullYear(newFromDate.getFullYear() - 5);
                    setFromDate(newFromDate);                
                    setToDate(newToDate);
                    break;
                case "MAX":
                default: 
                    setFromDate(null);
                    setToDate(null);
                    break;
            }
            // console.log(fromDate, toDate);
        }, 
        [timeSpan]
    ); 

    // Run whenever data or filterValue change
    useEffect(
        () => {
            if (data !== null) {
                let mask = new Set();
                data.map((d, idx) => { if (!d['chartTitle'].toLowerCase().includes(filterValue.toLowerCase())) { mask.add(idx); } return null;});
                setDataDisplayMask(mask); 
            }
        }, [data, filterValue]
    )

    // Run whenever mergeDialogSelection is clicked
    useEffect(
        () => {
            if (mergeDialogSelection !== null) {
                let mergeInto = {...data[mergeDialogSelection]};  // need a deep copy
                // console.log("mergeInto", mergeInto);
                const idxToMerge = Array.from(selectedTimeSeries).filter((idx) => idx !== mergeDialogSelection);
                const tsToMerge = idxToMerge.map((idx) => data[idx])
                // console.log("tsToMerge", tsToMerge);
                let result = mergeInto['series'];
    
                // Merge time series and sort by displayDate
                tsToMerge.forEach((ts) => { result = result.concat(ts['series']); })     
                // console.log(result);   
                result.sort(
                    (a, b) => { // ascending sort
                        if (a.data.displayDatetime < b.data.displayDatetime) { return -1 };
                        if (a.data.displayDatetime > b.data.displayDatetime) { return 1 };
                        return 0;
                    }
                );
                mergeInto['series'] = result;
    
                // if the date range is MAX, then fromDate and toDate are null, so we have to figure out the
                // min and max dates of the time series being exported and use those instead.
                let minDate = fromDate; 
                let maxDate = toDate;
                if (minDate === null || maxDate === null) {
                    const dates = mergeInto['series'].filter(
                                (dp) => { return dp['data']['canDisplay'] && dp['data']['isInDateRange'] }
                            ).map((dp) => { return dp['data']['displayDatetime'] })                                            
                    dates.sort()  
            
                    minDate = datefnstz_toDate(dates[0] + "T00:00:00", { timeZone: 'America/New_York'}) ?? fromDate;
                    maxDate = datefnstz_toDate(dates[dates.length - 1] + "T00:00:00", { timeZone: 'America/New_York'}) ?? toDate;
                }

                const filename = `${ticker}_${formType}_${minDate.toISOString().split('T')[0]}_${maxDate.toISOString().split('T')[0]}.xlsx`;
                exportXlsxWorkbook(filename, ticker, exchangeName, companyName, minDate, maxDate, [mergeInto]);
                ampli.mergeDataExport(
                    {
                        "Export Details": [
                            ticker,
                            mergeInto['formType'],
                            mergeInto['docType'].split('.')[0],
                            data[mergeDialogSelection]['description'],
                            tsToMerge.map(ts => ts.description),     
                            minDate.toISOString().split('T')[0],
                            maxDate.toISOString().split('T')[0]                                 
                        ]
                    }
                );
                setMergeDialogSelection(null);  // must clear it
            };
        }, [mergeDialogSelection]   // There are lots of dependencies that we don't include here on purpose.  
                                    // Including them will cause downloads whenever those deps change. 
    )


    // Run whenever sortKey changes
    useEffect( 
        () => {    
            if (data !== null) {
                // Pre-sort by chartTitle and modifier to make the result of other sort-by-key
                // much easier to skim through in UI. Otherwise, it is so difficult to brose them.
                let sortedData = sortBy(sortBy(data.slice(), 'modifier').slice(), "chartTitle").slice();            
                if (sortKey === "latestDate"){
                    sortedData = sortByLatestDate(sortedData.slice());
                } else if (sortKey === "length"){
                    sortedData = sortBySeriesLength(sortedData.slice());
                } else if (sortKey === "latestFilingDate") {
                    sortedData = sortByLatestFilingDate(sortedData.slice());
                } else if (sortKey === "score") {
                    sortedData = sortByScore(sortedData.slice());         
                    //console.log(sortedData[0].score, sortedData[sortedData.length - 1].score);
                } else {
                    sortedData = sortBy(sortedData.slice(), sortKey);
                }
                
                const sectionHeadersWithIndex = sectionHeaders.map(
                    (section, index) => ( 
                        { index, name: section }
                    )
                ).concat({ index: -1, name: "Other" });
                
                sortedData = sectionHeadersWithIndex.map(
                    (section) => {
                        return sortedData.filter((ts, idx) => ts.sectionIdx === section.index);
                    }
                ).flat();

                // Need to recompute selected timseries too!
                const newSelectedTimeSeries = new Set(sortedData.filter((item, index) => selectedTimeSeries.has(data.indexOf(item))).map((item) => sortedData.indexOf(item)));            
                setSelectedTimeSeries(newSelectedTimeSeries);                

                setData(sortedData); 
                setSelectedDatapoint(null);
            }
        }, [sortKey]    // We purposefully do not add data or selectedTimeSeries here as deps. Adding data will cause infinite
                        // rerenders.  It is OK because data only ever changes when we make an API call and that
                        // forces a reset of sortkey anyway.  In reality, we should be using an
                        // updater function inside, i.e., setData(prevData => ...)
    );

    /********************************** Event handlers ************************************/

    const onExportButtonClick = () => {
        let selectedIdx = Array.from(selectedTimeSeries);
        selectedIdx.sort();
        let timeSeriesToExport = selectedIdx.map((idx) => data[idx]);
        
        // if the date range is MAX, then fromDate and toDate are null, so we have to figure out the
        // min and max dates of the time series being exported and use those instead.
        let minDate = fromDate; 
        let maxDate = toDate;
        if (minDate === null || maxDate === null) {
            const dates = timeSeriesToExport.map(
                (ts) => { 
                    return ts['series'].filter(
                        (dp) => { return dp['data']['canDisplay'] && dp['data']['isInDateRange'] }
                        ).map((dp) => { return dp['data']['displayDatetime'] })
                }
            ).flat()
            dates.sort()  
    
            minDate = datefnstz_toDate(dates[0] + "T00:00:00", { timeZone: 'America/New_York'}) ?? fromDate;
            maxDate = datefnstz_toDate(dates[dates.length - 1] + "T00:00:00", { timeZone: 'America/New_York'}) ?? toDate;
        }

        const filename = `${ticker}_${formType}_${minDate.toISOString().split('T')[0]}_${maxDate.toISOString().split('T')[0]}.xlsx`;
        exportXlsxWorkbook(filename, ticker, exchangeName, companyName, minDate, maxDate, timeSeriesToExport);

        advanceWizardState([4, 5], wizardState, setWizardState);
        //setWizardCookie(false);
    };

    // debounce function
    function debounce(func, wait = 500) {
            let timeout;
            function debounced(...args) {
            const later = () => {
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            }
        
            debounced.clear = () => {
            clearTimeout(timeout);
            };
    
        return debounced;
    }

    const debouncedHandleKeywordSearch = useMemo(
        () => debounce(handleKeywordSearchQuery, 1000),
        [],
    );

    // Function to send Amplitude event
    function handleKeywordSearchQuery(query) {
        const fd = new Date(fromDate).toLocaleDateString('en-US');    
        const td = new Date(toDate).toLocaleDateString('en-US');  
        ampli.keywordSearch({ "Query Date Range": [fd, td], "Query Form Type": formType, "Query Symbol": ticker, "Keyword": query});    
    }

    const onKeywordFilterTimeSeries = (event) => {
        setFilterValue(event.target.value); 
        debouncedHandleKeywordSearch(event.target.value);       
    } 

    // Sort selection change
    const handleSortChange = (event) => {
        // reset the expansion
        const startState = new Array(chartExpandState).fill(false);
        startState[0] = true;
        setChartExpandState(startState);
        
        setSortKey(event.target.value);
        ampli.sort({"Sort Type": event.target.value});
        //handleSort(event.target.value);
    };

    const handleTimeSpanChange = (event, state) => {     
        if (state !== null) {            
            setTimeSpan(state);
            ampli.timeSpanSelection({"Time Span": state});
        }      
    }

    //------ Merge download handling -------------------------------------------
    const handleSplitButtonToggle = () => {      
        setSplitButtonOpen((prev) => { return !prev });
    }

    const handleSplitButtonClose = () => {
        setSplitButtonOpen(false);
    }

    const handleMergeDialogClose = () => {
        setMergeDialogOpen(false);
    }

    const handleMergeDialogSelect = (idx) => {
        setMergeDialogSelection(idx);
        setMergeDialogOpen(false);
    } 

    const onMergeExportClick = () => {    
        const tsList = Array.from(selectedTimeSeries);
        
        // Check units are compatible
        const units = Array.from(new Set(tsList.map(
            (ts) => {                
                return data[ts]['unit'];
            }
        )));         

        if (units.length > 1) {
            setUnitsErrorDialogOpen(true);
        } else {
            const selectionLabels = Array.from(new Set(tsList.map(
                (ts) => {
                    return data[ts]['chartTitle'] + (data[ts]['modifier'] ? " -- for " + data[ts]['modifier'] : "");
                }
            )));
            // console.log(selectionLabels);
            // console.log(selectedTimeSeries);
            // console.log(mergeDialogSelection);
            if (selectionLabels.length > 1) {
                setMergeDialogOpen(true);                
            } else {
                setMergeDialogSelection(tsList[0]);            
            }            
        }
        setSplitButtonOpen(false);
    }

    
    /***********************************************************************************************/
    
    return (
        <div name="DataPane">            
            <Dialog
                PaperProps={{sx: {backgroundColor: "var(--mui-palette-error-light)", borderRadius: "16px", padding: 5}}}
                open={unitsErrorDialogOpen}
                onClose={()=>{setUnitsErrorDialogOpen(false)}}>
                    <DialogContentText><Typography variant="h6" sx={{color: "var(--mui-palette-primary-contrastText)"}}>The selected time series have different units and cannot be merged.</Typography></DialogContentText>
            </Dialog>
            <Dialog 
                PaperProps={{sx: {borderStyle: "solid", borderWidth: "5px", borderColor: "var(--brand-main)", borderRadius: "16px", padding: 5}}}                
                open={mergeDialogOpen} 
                maxWidth="md"
                onClose={handleMergeDialogClose}>
                <DialogTitle sx={{padding: 0, textAlign: "left"}}>Please select the time series to merge into:</DialogTitle>
                <List sx={{ pt: 0 }}>
                  {Array.from(selectedTimeSeries).map((idx) => (
                    <ListItem disableGutters key={idx}>
                      <ListItemButton onClick={() => handleMergeDialogSelect(idx)}>
                        <ListItemIcon><LabelIcon size="small"/></ListItemIcon><ListItemText primary={data[idx]['chartTitle'] + (data[idx]['modifier'] ? " -- for " + data[idx]['modifier'] : "")} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
            </Dialog>

            <Container disableGutters maxWidth="false" mx="auto" sx={{borderRight: 1, borderColor: "grey.800"}}>
                <Stack>
                    <SelectedTimeSeriesContext.Provider value={[selectedTimeSeries, setSelectedTimeSeries]}>
                    <Paper sx={{pb: "5px", borderBottom: 1, borderRadius: 0, borderColor: "grey.500"}}>
                            <Stack>
                            <Typography sx={{height: "35px", lineHeight: '1.0',  ml: "10px", mt: 0.5, color: "var(--brand-main)"}} variant="h4">{companyName}</Typography>
                            <Typography sx={{height: "15px", ml: "10px", mb: 2}} variant="h6">{exchangeName ? exchangeName + ': ' + ticker.toUpperCase() : ''}</Typography>
                                <Grid container sx={{border: "", borderColor: "#ffffff", borderWidth: "1px", ml: "10px", mr: "10px"}}>
                                    <Grid xs={2.5} container justifyContent="left" sx={{border: "", borderColor: "#ffffff", borderWidth: "1px"}} pr={1}>                                        
                                        <TextField 
                                            // sx={{ml: '10px', mr: "10px"}}
                                            placeholder="Keyword or (unit)"
                                            disabled={isDataEmpty} 
                                            fullWidth
                                            size="small"
                                            onChange={onKeywordFilterTimeSeries}
                                            onFocus={(e) => {e.stopPropagation()}}
                                            value={filterValue}
                                            InputProps={{
                                                startAdornment: (
                                                  <InputAdornment position="start">
                                                    <SearchIcon />
                                                  </InputAdornment>
                                                ),                                        
                                                endAdornment: (filterValue !== "") && (
                                                  <IconButton                                                    
                                                    onClick={() => { setFilterValue(""); setDataDisplayMask(new Set());}}
                                                  ><ClearIcon/></IconButton>
                                                ), 
                                              }}
                                            />
                                    </Grid>
                                    <Grid xs={2} container justifyContent="left" sx={{border: "", borderColor: "#ffffff", borderWidth: "1px"}} pr={1}>
                                        <FormControl fullWidth size="small" >
                                            <InputLabel id="sort-by-label">Sort By</InputLabel>
                                            <Select
                                                labelId="sort-by-label"
                                                id="sort-by"
                                                value={sortKey}
                                                label="Sort By"
                                                onChange={handleSortChange}
                                            >
                                                <MenuItem value="score">SmartRank <Tooltip display="flex" title={<h3>SmartRank is our experimental, proprietary way of ranking how interesting an extracted time series may be. </h3>}><AutoAwesomeIcon fontSize="0.8rem" sx={{color: "var(--brand-analogous)", ml: "1rem"}}/></Tooltip></MenuItem>
                                                <MenuItem value="chartTitle">Name (A - Z)</MenuItem>
                                                <MenuItem value="unit">Units (A - Z)</MenuItem>
                                                <MenuItem value="length">Longest first</MenuItem>
                                                <MenuItem value="latestDate">Latest datapoint</MenuItem>
                                                <MenuItem value="latestFilingDate">Most recent filing</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>        
                                    <Grid xs={2} container justifyContent="left" sx={{border: "", borderColor: "#ffffff", borderWidth: "1px"}} pr={1}>
                                        <ToggleButtonGroup fullWidth exclusive value={timeSpan} onChange={handleTimeSpanChange} size="small">
                                            <ToggleButton value="5Y">5Y</ToggleButton>
                                            <ToggleButton value="10Y">10Y</ToggleButton>
                                            <ToggleButton value="MAX">MAX</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>                            
                                    <Grid xs={5.5} container sx={{border: "", borderColor: "#ffffff", borderWidth: "1px", justifyContent: "flex-end"}}>
                                        <ButtonGroup variant="contained" ref={splitButtonAnchorRef}>
                                            <Tooltip title={selectedTimeSeries.size > 1 && <h3>You can merge your selections into a single time series before exporting by clicking the arrow to the right.</h3>}>        
                                                <span>
                                                <OnboardingWizard step={5} placement="bottom">
                                                <Button variant='contained' sx={{ height: "100%", mr: "1px"}} display="flex" onClick={onExportButtonClick} disabled={isDataEmpty || selectedTimeSeries.size === 0}>
                                                    <DownloadIcon sx={{mr: "5px"}}/>Export selections to Excel                                                    
                                                </Button>                                 
                                                </OnboardingWizard>
                                                </span>
                                            </Tooltip>                                            
                                            <Button size="small" disabled={isDataEmpty || selectedTimeSeries.size < 2} onClick={handleSplitButtonToggle}><ArrowDropDownIcon/></Button>                                                                                  
                                        </ButtonGroup>
                                        <Popper sx={{zIndex: 1, p: '2px'}} placement="bottom-end" anchorEl={splitButtonAnchorRef.current} open={splitButtonOpen} transition disablePortal>                                        
                                            {({ TransitionProps, placement }) => (
                                                <Grow {...TransitionProps}>            
                                                    <Paper>                                  
                                                        <ClickAwayListener onClickAway={handleSplitButtonClose}>
                                                        <   Button variant="contained" onClick={onMergeExportClick}>Merge selections, then export to excel</Button>                                            
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </Grid>                                    
                                </Grid>                                
                            </Stack>                           
                    </Paper>
                    <Box sx={{height: "calc(100vh - 15px - 67px - 61px - 120px)", maxHeight: "calc(100vh - 15px - 67px - 61px - 120px)", overflowY: "scroll"}} align="center" alignItems="center" justifyContent="center">                        
                        {
                            apiError 
                            ? 
                            <Box width="50%" maxWidth="50%" height="100%" align="center" alignItems="center" display="flex" justifyContent="center">
                                <Alert severity="error" variant="filled" size="md">An error occured during your search.  Please check your search parameters and try again.</Alert>
                            </Box>
                            :
                            <DateRangeContext.Provider value={[fromDate, toDate]}>
                                <ChartExpandStateContext.Provider value={[chartExpandState, setChartExpandState]}>                                    
                                    <ChartList 
                                        data={data} 
                                        displayMask={dataDisplayMask}                                     
                                        filterValue={filterValue} 
                                        sectionHeaders={sectionHeaders} />
                                </ChartExpandStateContext.Provider>
                            </DateRangeContext.Provider>
                        }
                    </Box>
                    </SelectedTimeSeriesContext.Provider>
                </Stack>
            </Container>                        
        </div>
    );
}

export default DataPane;
