export const items = [
    {
        "name": "[NYSE: TWLO]&nbsp;&nbsp;Cloud infrastructure fees ($ change)",
        "data": {
            "minX": "2016-06-30",
            "maxX": "2023-06-30",
            "ts": [
                {"displayDatetime": "2016-12-31", "displayValue": "2800000.0"},
                {"displayDatetime": "2017-12-31", "displayValue": "4800000.0"},
                {"displayDatetime": "2018-12-31", "displayValue": "7600000.0"},
                {"displayDatetime": "2019-12-31", "displayValue": "18600000.0"},
                {"displayDatetime": "2020-12-31", "displayValue": "32300000.0"},
                {"displayDatetime": "2021-12-31", "displayValue": "44200000.0"},
                {"displayDatetime": "2022-12-31", "displayValue": "36500000.0"}
            ]
        }
    }, 
    {
        "name": "[NYSE: SUN]&nbsp;&nbsp;Refined product terminals",
        "data": {
            "minX": "2016-06-30",
            "maxX": "2023-06-30",
            "ts": [
                {'displayDatetime': '2016-12-31', 'displayValue': '8.0'},
                {'displayDatetime': '2017-12-31', 'displayValue': '8.0'},
                {'displayDatetime': '2018-12-31', 'displayValue': '13.0'},
                {'displayDatetime': '2019-12-31', 'displayValue': '13.0'},
                {'displayDatetime': '2020-12-31', 'displayValue': '14.0'},
                {'displayDatetime': '2021-12-31', 'displayValue': '24.0'},
                {'displayDatetime': '2022-12-31', 'displayValue': '27.0'}
            ]
        }
    },
    {
        "name": "[NASDAQ: META]&nbsp;&nbsp;ARPU; Asia-Pacific (% change)",
        "data": {
            "minX": "2014-01-01",
            "maxX": "2023-12-31",
            "ts": [
                {'displayDatetime': '2014-03-31', 'displayValue': '45.0'},
                {'displayDatetime': '2014-06-30', 'displayValue': '44.0'},
                {'displayDatetime': '2015-03-31', 'displayValue': '27.0'},
                {'displayDatetime': '2015-06-30', 'displayValue': '19.0'},
                {'displayDatetime': '2015-09-30', 'displayValue': '18.0'},
                {'displayDatetime': '2016-03-31', 'displayValue': '32.0'},
                {'displayDatetime': '2016-06-30', 'displayValue': '37.0'},
                {'displayDatetime': '2017-03-31', 'displayValue': '27.0'},
                {'displayDatetime': '2017-06-30', 'displayValue': '20.0'},
                {'displayDatetime': '2017-09-30', 'displayValue': '20.0'},
                {'displayDatetime': '2018-03-31', 'displayValue': '24.0'},
                {'displayDatetime': '2018-06-30', 'displayValue': '23.0'},
                {'displayDatetime': '2018-09-30', 'displayValue': '18.0'},
                {'displayDatetime': '2019-03-31', 'displayValue': '13.0'},
                {'displayDatetime': '2019-06-30', 'displayValue': '16.0'},
                {'displayDatetime': '2019-09-30', 'displayValue': '21.0'},
                {'displayDatetime': '2020-03-31', 'displayValue': '10.0'},
                {'displayDatetime': '2020-06-30', 'displayValue': '-2.0'},
                {'displayDatetime': '2020-09-30', 'displayValue': '13.0'},
                {'displayDatetime': '2021-03-31', 'displayValue': '29.0'},
                {'displayDatetime': '2021-06-30', 'displayValue': '39.0'},
                {'displayDatetime': '2021-09-30', 'displayValue': '17.0'},
                {'displayDatetime': '2022-03-31', 'displayValue': '13.0'},
                {'displayDatetime': '2022-06-30', 'displayValue': '9.0'},
                {'displayDatetime': '2022-09-30', 'displayValue': '3.0'},
                {'displayDatetime': '2023-06-30', 'displayValue': '7.0'},
                {'displayDatetime': '2023-09-30', 'displayValue': '16.0'}
            ]
        }
    },
    {
        "name": "[NYSE: SNOW]&nbsp;&nbsp;Customers; with trailing 12-month product revenue greater than $1 million",
        "data": {
            "minX": "2020-06-30",
            "maxX": "2024-01-30",
            "ts": [
                {'displayDatetime': '2020-10-31', 'displayValue': '65.0'},
                {'displayDatetime': '2021-01-31', 'displayValue': '77.0'},
                {'displayDatetime': '2021-04-30', 'displayValue': '104.0'},
                {'displayDatetime': '2021-07-31', 'displayValue': '116.0'},
                {'displayDatetime': '2021-10-31', 'displayValue': '148.0'},
                {'displayDatetime': '2022-01-31', 'displayValue': '184.0'},
                {'displayDatetime': '2022-04-30', 'displayValue': '206.0'},
                {'displayDatetime': '2022-07-31', 'displayValue': '246.0'},
                {'displayDatetime': '2022-10-31', 'displayValue': '287.0'},
                {'displayDatetime': '2023-01-31', 'displayValue': '330.0'},
                {'displayDatetime': '2023-04-30', 'displayValue': '373.0'},
                {'displayDatetime': '2023-07-31', 'displayValue': '402.0'},
                {'displayDatetime': '2023-10-31', 'displayValue': '436.0'}
            ]
        }
    },
    {
        "name": "[NYSE: SNAP]&nbsp;&nbsp;DAUs",
        "data": {
            "minX": "2017-01-01",
            "maxX": "2023-12-31",
            "ts": [
                {'displayDatetime': '2017-03-31', 'displayValue': '166000000.0'},
                {'displayDatetime': '2017-06-30', 'displayValue': '173000000.0'},
                {'displayDatetime': '2017-09-30', 'displayValue': '178000000.0'},
                {'displayDatetime': '2018-03-31', 'displayValue': '191000000.0'},
                {'displayDatetime': '2018-06-30', 'displayValue': '188000000.0'},
                {'displayDatetime': '2018-09-30', 'displayValue': '186000000.0'},
                {'displayDatetime': '2019-03-31', 'displayValue': '190000000.0'},
                {'displayDatetime': '2019-06-30', 'displayValue': '203000000.0'},
                {'displayDatetime': '2019-09-30', 'displayValue': '210000000.0'},
                {'displayDatetime': '2020-03-31', 'displayValue': '229000000.0'},
                {'displayDatetime': '2020-06-30', 'displayValue': '238000000.0'},
                {'displayDatetime': '2020-09-30', 'displayValue': '249000000.0'},
                {'displayDatetime': '2021-03-31', 'displayValue': '280000000.0'},
                {'displayDatetime': '2021-06-30', 'displayValue': '293000000.0'},
                {'displayDatetime': '2021-09-30', 'displayValue': '306000000.0'},
                {'displayDatetime': '2022-03-31', 'displayValue': '332000000.0'},
                {'displayDatetime': '2022-06-30', 'displayValue': '347000000.0'},
                {'displayDatetime': '2022-09-30', 'displayValue': '363000000.0'},
                {'displayDatetime': '2023-03-31', 'displayValue': '383000000.0'},
                {'displayDatetime': '2023-06-30', 'displayValue': '397000000.0'},
                {'displayDatetime': '2023-09-30', 'displayValue': '406000000.0'}
            ]
        }
    }


];

export default items;


