import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import './../../App.css';

// import { useAuthenticator } from '@aws-amplify/ui-react';
import { DataPane } from './DataPane';
import { SearchBar } from './SearchBar';
import { Box, Typography, List, ListItem, Stack, ListItemText } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ProvenancePane from './ProvenancePane';

import CheckIcon from '@mui/icons-material/Check';

import { DisplayedCikContext, SearchBarContext, SelectedDatapointContext, OnboardingWizardContext } from './Contexts';
import { SUPPORTED_FORM_TYPES } from './constants';
// import { isValidDate } from '../Utils';
import { ShowOnceModal } from '../ModalMessage';
import { ONBOARDING_STEPS, OnboardingFinished} from './Onboarding';

// import { encodeDatapoint } from './DatapointProvenanceApi';


export function AppView() {
    const [params, setSearchParams] = useSearchParams();
    //console.log("AppView", params, params.get('to'), params.get('from'))

    // Set the defaults.  If there are querystring params already, then it is possible user came in
    // from a bookmark.  In this case, we need to validate and then pre-populate the SearchBar by
    // setting the default state here.  
    const [ticker, setTicker] = useState(params.get('ticker') ? params.get('ticker') : null); 
    const [formType, setFormType] = useState(params.get('formType')
                                                && SUPPORTED_FORM_TYPES.includes(params.get('formType'))
                                             ? params.get('formType')
                                             : '10-K'); 

    const [selectedDatapoint, setSelectedDatapoint] = useState(null);
    const [displayedCik, setDisplayedCik] = useState(null);
    
        
    const [wizardState, setWizardState] = useState(
            () => {                
                const storedState = localStorage.getItem('DEEPKPI_onboardingWizardState'); 
                //console.log("init", storedState);
                return storedState === null ? Object.keys(ONBOARDING_STEPS).map((x) => Number(x)).sort() : JSON.parse(storedState);
            }
    );

    

    // When the querystring parameters change, then we need to update state. 
    useEffect(
        () => {            
            // const defaultFromDate = new Date();
            // defaultFromDate.setFullYear(defaultFromDate.getFullYear() - 1);
            
            setTicker(params.get('ticker'));
            setFormType(
                params.get('formType') && SUPPORTED_FORM_TYPES.includes(params.get('formType'))
                ? params.get('formType')
                : '10-K'
            );
            setSelectedDatapoint(null);
        }, 
        [params]
    );
    
    return (
        <OnboardingWizardContext.Provider value={[wizardState, setWizardState]}>
        <Stack>
            <Box sx={{display: {xs: 'block', md: 'none', lg: 'none'}}}>
                <div style={{ textAlign: 'center', padding: 20 }}>
                    <Typography variant="h6" gutterBottom color="var(--brand-main)">
                        This website is currently optimized for desktop viewing.
                    </Typography>
                    <Typography variant="body1" color="var(--brand-text-secondary)">
                        We're working on bringing a mobile-friendly experience to you soon. In the meantime, you can visit the site from a desktop computer or a laptop to get started!
                    </Typography>
                </div>
            </Box>
            <Box sx={{display: {xs: 'none', md: 'block', lg: 'block'}}}>
                <OnboardingFinished>
                    <Typography sx={{fontSize: "1.25rem"}}><b>Congratulations &mdash; you've exported your first deepKPI time series!</b></Typography>
                    <br/>
                    <Typography>
                        Please feel free to try some of our other features, such as keyword and time range filtering, 
                        as well as sorting.
                    </Typography>
                </OnboardingFinished>
                <ShowOnceModal cookieName="DEEPKPI_betaLoginModal">
                    <Typography variant="h4" color="var(--brand-main)" paragraph={true}>Welcome to the deepKPI Beta Program</Typography>
                    <Typography variant="h6" paragraph={true}>Thank you for joining a select group of early-adopters to test our platform!</Typography>
                    <Typography variant="h6" paragraph={true}>
                        In this preview phase, you can find KPI time series from:
                        <List disablePadding>
                        <ListItem sx={{ml: '5px', pb: 0, pt: 0}}><CheckIcon sx={{mr: "5px"}}/><ListItemText><Typography variant="h6">Companies in the DJIA, S&P 500, S&P 400, and S&P 600 (<a href="https://docs.google.com/document/d/1CU3OkgcYUykqbzEMMA7fXM32OkyAhWvPI1hW35U6pfA" target="_blank">full list</a>)</Typography></ListItemText></ListItem>
                        <ListItem sx={{ml: '5px', pb: 0, pt: 0}}><CheckIcon sx={{mr: "5px"}}/><ListItemText><Typography variant="h6">10-Ks, 10-Qs, and 8-Ks filed on January 1, 2014 to the present.</Typography></ListItemText></ListItem>
                        </List>
                    </Typography>
                    <Typography variant="h6" paragraph={true}>
                        During the Beta Program we will be continuously improving deepKPI. As a result, you may notice updates to both the website and the available data.
                    </Typography>
                    <Typography variant="h6" paragraph={true}>
                    At the end of this preview period, we'll be asking you to complete a short survey and may follow up to conduct research interviews. Thank you in advance for your feedback!
                    </Typography>
                    <Typography variant="h6" paragraph={true}>
                        In the meantime, if you notice anything unexpected, please take a look at the <Link to="/support">FAQ</Link> on our Support page, or contact us any time at <a href="mailto:support@revelata.com" >support@revelata.com</a>.
                    </Typography>
                    <Typography variant="h6" paragraph={true}>
                        Thanks!
                        <br/>
                        Team Revelata                    
                    </Typography>
                </ShowOnceModal>
                <Box sx={{minWidth: "1440px", padding: "0px"}}>            
                    {/* <SearchBarContext.Provider value={[ticker, formType, fromDate, toDate]}> */}
                    <SearchBarContext.Provider value={[ticker, formType]}>
                    <SearchBar />
                    { (ticker === null || formType === null)
                        ? null
                        :
                        <DisplayedCikContext.Provider value={[displayedCik, setDisplayedCik]}>
                        <SelectedDatapointContext.Provider value={[selectedDatapoint, setSelectedDatapoint]}>
                        
                        <Grid container>               
                            <Grid xs={6} md={6} lg={6}>
                                <DataPane />
                            </Grid>
                            <Grid xs={6} md={6} lg={6}>                                
                                <ProvenancePane provenancePaneHeight="calc(100vh - 15px - 67px - 61px - 120px)"/>                                
                            </Grid>                
                        </Grid>
                        
                        </SelectedDatapointContext.Provider>
                        </DisplayedCikContext.Provider>
                    }
                    </SearchBarContext.Provider>
                    <Box position="absolute" bottom="5px" sx={{minWidth: "1280px", width: "100%", height: "10px", maxHeight: "10px", border:0}}>  {/*display="flex" justifyContent="flex-end"*/}
                        <Typography align="center" fontFamily="lato" fontSize="0.6rem">Copyright &copy; 2024 Revelata, Inc.&nbsp;&nbsp;All rights reserved. <a href="/static/tos.html" _target="blank">Terms of Service</a></Typography>
                    </Box>
                </Box>
            </Box>
        </Stack>
        </OnboardingWizardContext.Provider>
    );
}

export default AppView;

