import './../../App.css';
import { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Auth } from 'aws-amplify';
import { 
    Accordion, AccordionDetails, AccordionSummary, 
    FormGroup, FormControlLabel,
    Checkbox, Stack, useTheme, CircularProgress, Typography, Tooltip, 
    List, ListItem, ListItemText, Chip, Grid 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CallMergeOutlinedIcon from '@mui/icons-material/CallMergeOutlined';
import { toDate as datefnstz_toDate } from 'date-fns-tz';

import { API_URL, makePostRequest } from '../RestApi';
import { 
    SelectedDatapointContext, 
    SelectedTimeSeriesContext, 
    DisplayedCikContext, 
    SearchBarContext, 
    DateRangeContext, 
    ChartExpandStateContext, 
    OnboardingWizardContext
} from './Contexts';
import { ampli } from '../../ampli/index.ts';
import { OnboardingWizard, advanceWizardState } from './Onboarding.js';

// const OneYearThreshold = 365 * 24 * 60 * 60 * 1000;

Highcharts.setOptions(
    {
        lang: {
            thousandsSep: ',',
            numericSymbols: ["k", "M", "B", "T", "P", "E"]
        }
    }
)

function hasOnlyMinusOneKey(dict) {
    // Check if the dictionary is empty
    if (Object.keys(dict).length === 0) {
      return false; // Empty dictionary cannot have a single key
    }
  
    // Check if there's only one key and it's -1
    return Object.keys(dict).length === 1 && dict[-1] !== undefined;
  }

function findDuplicateCharts(data, displayMask) {
    const duplicateIndexes = [];
    const seenCharts = new Map();
  
    data.forEach((item, index) => {
      const key = item.chartTitle + "@@@" + item.modifier;
      if (!displayMask.has(index) && item.shouldDisplay) {
        if (seenCharts.has(key)) {
            duplicateIndexes.push(index, seenCharts.get(key));
          } else {
            seenCharts.set(key, index);
          }
      }
      
    });
  
    return duplicateIndexes;
  }

function formatSeriesForHighCharts(raw_series, clearSelection) {    
    // if (raw_series === undefined || raw_series === null) {
    //     return;
    // }
    const ts = raw_series['series'];
    //console.log("formatSeriesForHighCharts", ts);
    const datapoints = ts.filter(
        (dp) => {
            //console.log("\t", dp['data']['canDisplay'], dp['data']['isInDateRange']);
            return dp['data']['canDisplay'] && dp['data']['isInDateRange'];
        }
    );
    //console.log("formatSeriesForHighCharts", datapoints);
    const output = datapoints.map(
        (dp, idx) => {
            const out = {
                x: datefnstz_toDate(dp['data']['displayDatetime'] + "T00:00:00", { timeZone: 'America/New_York'}).getTime(),
                y: Number.parseFloat(dp['data']['displayValue']),
                marker: { radius: 5 },
                custom: dp,                       
            }

            // Only set this if clearSelection is true (this clears all selections except for the
            // currently selectedDatapoint).
            if (clearSelection)
            {
                out['selected'] = false;
            }
            return out;
        }
    ); 
    //console.log("formatSeriesForHighCharts", output);
    
    
    return output;
}

export function TimeSeriesChartView({idx, isFirstChart, data, minX, maxX, expanded, flipExpandedState, latestFilingData, hasDuplicate, ticker}) {    
    const muiTheme = useTheme();    
    
    let containsLatestFiling;
    if ((Object.keys(latestFilingData).length === 0)) {
        containsLatestFiling = false;
    } else {
        
        const latestDatapoint = (data.series).reduce((latest, item) => {
            return item.filingDate > latest.filingDate && item.data.canDisplay && item.data.isInDateRange ? item : latest;
            }, (data.series)[0]);
        containsLatestFiling = Object.values(latestFilingData).some((item) => item.acc_no === latestDatapoint.accNo);
    }

    // const chartKey = useRef(0);
    
    const [ wizardState, setWizardState ] = useContext(OnboardingWizardContext);
    const [ selectedDatapoint, setSelectedDatapoint ] = useContext(SelectedDatapointContext);
    const [ selectedTimeSeries, setSelectedTimeSeries ] = useContext(SelectedTimeSeriesContext);
    const formattedDataSeriesForHighCharts = formatSeriesForHighCharts(data, selectedDatapoint && selectedDatapoint['chartId'] !== idx)
    // console.log(data, formattedDataSeriesForHighCharts);

    const hcOptions = {
        credits: { enabled: false },        
        xAxis: {            
            type: "datetime",
            className: "revelata-highcharts-font",
            labels: { 
                style: { 
                    color: muiTheme.palette.text.primary, 
                    cursor: "default",
                    fontSize: "0.8rem"
                }
            },
            min: minX, 
            max: maxX            
        },
        yAxis: {
            title: {
                text: null,
            },
            className: "revelata-highcharts-font",
            labels: { 
                style: { 
                    color: muiTheme.palette.text.primary, 
                    cursor: "default",
                    fontSize: "0.8rem"
                }
            }
        },
        tooltip: {
            xDateFormat: "%Y-%m-%d",
            pointFormat: "{point.y}",
            //pointFormatter: function () { return data['data']['u'] + this.y },
            className: "highcharts-light revelata-highcharts-font"
        },
        series: [
            {   
                name: '',
                data: formattedDataSeriesForHighCharts,                                
            }
        ], 
        title: { text: '' },
        legend:{ enabled: false },
        accessibility: {
            enabled: false,
        },
        chart: {
            styleMode: true,
            type: "line", //"spline"
            height: "275px", 
            time: {
                timeZone: "America/New_York"
            },
            borderWidth: 1,
            borderColor: "var(--mui-palette-grey-600)",
            borderRadius: 7, 
            spacing: [20, 15, 15, 15]
        },
        plotOptions: {
            //spline: { allowPointSelect: true },
            line: { allowPointSelect: true }, 
            series: {   
                // marker: {
                //     states: {
                //         select: {
                //             fillColor: '#ff0000',
                //         }
                //     }
                // },         
                marker: {
                    enabled: true
                },
                point:  { events: { select: function() {
                    if (selectedDatapoint !== null & isFirstChart) {
                        console.log("click point", wizardState);
                        setTimeout(() => advanceWizardState(3, wizardState, setWizardState), 
                            3000
                        );
                    }                    
                    setSelectedDatapoint({'chartId': idx, 'dp': this.custom});                     
                    if (this.custom) {   
                        ampli.dataAudit( {
                            "Provenance Details": [
                                this.custom['cik'], 
                                this.custom['accNo'], 
                                this.custom['seqNo'].toString(), 
                                JSON.stringify(this.custom['xpathNodes'])],
                            "Filing Date": this.custom['filingDate'],
                            "Reporting Period": this.custom['reportingPeriod'],
                            "Sentence":  this.custom['sentence'],
                            "Display Datetime": this.custom['displayDatetime'],
                            "Display Value": this.custom['Display Value'],
                            "Provenance Caller": "app",
                            "Query Symbol": ticker,
                            "Chart Title": data.chartTitle,
                            "Description": data.description,
                            "Doc Type": data.docType,
                            "Form Type": data.formType,
                            "Hint": data.hint,
                            "Modifier": data.modifier,
                            "Score": data.score.aggregated,
                            "Section Idx": data.sectionIdx,
                            "Unit": data.unit,
                            "Chart Index": idx,
                            "Series Length": formattedDataSeriesForHighCharts.length,
                            "Contains Latest Filing": containsLatestFiling,
                            "Has Duplicate Time Series": hasDuplicate
                            }                            
                        );            
                    }
                } } }
            }
        }
    }

    // Automatically select the last datapoint if this is the first chart rendered in ChartList.
    const afterChartCreated = (chart) => {        
        if (isFirstChart && chart.series[0]) {
            chart.series[0].data[chart.series[0].data.length - 1].select(true,false);
        }
    }

    const handleCheckboxChange = (event) => {
        // console.log(selectedTimeSeries);
        const updated = new Set([...selectedTimeSeries]);
        let isAlreadySelected = updated.has(idx)
        if (isAlreadySelected) {
            updated.delete(idx);
        } else {
            updated.add(idx);
        }
        // console.log(updated);
        setSelectedTimeSeries(updated); 

        advanceWizardState(4, wizardState, setWizardState);

        ampli.timeSeriesChartViewSelection({
            "Query Symbol": ticker,
            "Chart Title": data.chartTitle,
            "Description": data.description,
            "Doc Type": data.docType,
            "Form Type": data.formType,
            "Hint": data.hint,
            "Modifier": data.modifier,
            "Score": data.score.aggregated,
            "Section Idx": data.sectionIdx,
            "Unit": data.unit,
            "Chart Index": idx,
            "Series Length": formattedDataSeriesForHighCharts.length,
            "Contains Latest Filing": containsLatestFiling,
            "Expanded": !isAlreadySelected,
            "Has Duplicate Time Series": hasDuplicate
        })
           
    }

    return (        
        <Accordion 
            disableGutters 
            expanded={expanded} 
            onChange={
                () => {
                    flipExpandedState(idx, formattedDataSeriesForHighCharts.length, hasDuplicate, containsLatestFiling);
                }
            } 
            TransitionProps={{ unmountOnExit: true }}
            >
            <AccordionSummary sx={{maxHeight: "25px", paddingLeft: "30px", paddingTop: "30px", paddingBottom: "30px"}} expandIcon={<ExpandMoreIcon/>}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item lg={7.5} xl={7.5}>
                        <FormGroup row={true}>
                            <FormControlLabel 
                                control={
                                    isFirstChart ? 
                                    <OnboardingWizard step={4} placement="bottom-start">
                                        <Checkbox size="small"
                                            checked={selectedTimeSeries.has(idx)}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={handleCheckboxChange}/>   
                                    </OnboardingWizard> 
                                    : 
                                        <Checkbox size="small"
                                        checked={selectedTimeSeries.has(idx)}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={handleCheckboxChange}/>   
                                    }
                                label={
                                    <Stack direction="row">
                                        <Typography sx={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                // display: "-webkit-box",
                                                // WebkitLineClamp: "2",
                                                // WebkitBoxOrient: "vertical",
                                                textAlign: "left",
                                                fontSize: "0.9rem"
                                                }}>
                                                {data['hint']? data['description'] + " (" + data['hint'] + ")": data['description']}
                                                
                                        </Typography>
                                        {hasDuplicate &&<Tooltip title={<h3>We found other time series similar to this one.  You can select and merge them into this one, before exporting to Excel.</h3>}>
                                        <CallMergeOutlinedIcon sx={{color: "var(--brand-complementary)"}}/></Tooltip>}
                                    </Stack>}
                                sx={{textAlign: 'left'}}                         
                            />                    
                        </FormGroup>
                    </Grid>
                    <Grid item lg={2} xl={2} padding={1}>
                        <Tooltip title={<h3>Reported in units of {data['unit']}</h3>}>
                        <Typography  sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "0.9rem",  
                            textAlign: "left", color: "var(--mui-palette-grey-600)"
                            }}>
                            {data['unit']}
                        </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={1} xl={1} padding={1}>
                    {/* <Stack direction="row" spacing={2} justifyContent="center"> */}
                        <Tooltip title={<h3>Reporting frequency</h3>}>
                        <Typography component={'span'} display="flex" sx={{
                            fontSize: "0.9rem", 
                            textAlign: "left", 
                            color: "var(--mui-palette-grey-600)"}}>
                            {
                                data['modifier'] === "fiscal years" 
                                ? "annual"                                 
                                : data['modifier'] === 'twelve months ended'
                                    ? "annual" 
                                    : data['modifier']==="quarters ended" 
                                        ? "quarterly"
                                        : data['modifier']
                            }
                        </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={1.5} xl={1.5} padding={1}>                        
                        {containsLatestFiling && (
                                <Tooltip title={<h3>A fresh datapoint was reported in the most recent filing.</h3>} sx={{p: 100, ml: 100,}}>
                                <Chip label="Recent" size="small" variant="outlined" sx={{overflow: "hidden", fontSize: "0.85rem", color: "var(--brand-analogous)"}} />
                                </Tooltip>
                            )}
                    {/* </Stack> */}
                    </Grid>
                </Grid>
                {/* <Box>
                    <Checkbox size="small" 
                        checked={selectedTimeSeries.has(idx)} 
                        onClick={(e) => e.stopPropagation()} 
                        onChange={handleCheckboxChange}/>
                    {data['d']} ({data['u']})
                </Box> */}
            </AccordionSummary>
            <AccordionDetails sx={{height: "300px", width: "96%"}}>
                {isFirstChart ?
                    <OnboardingWizard step={3} placement="right-end"><div>
                        <HighchartsReact                    
                            key={data['description'] + data['hint'] + data['unit'] + data['modifier']}
                            highcharts={Highcharts}
                            options={hcOptions}
                            callback={afterChartCreated}                    
                            containerProps = {{ className: 'highcharts-dark revelata-highcharts-font' }}
                        /></div>
                    </OnboardingWizard> 
                    : 
                    <HighchartsReact                    
                        key={data['description'] + data['hint'] + data['unit'] + data['modifier']}
                        highcharts={Highcharts}
                        options={hcOptions}
                        callback={afterChartCreated}                    
                        containerProps = {{ className: 'highcharts-dark revelata-highcharts-font' }}
                    />
                }
            </AccordionDetails>
        </Accordion>
        
    )
}

export function ChartList({data, displayMask, filterValue, sectionHeaders}) {    
    const [sectionExpandState, setSectionExpandState] = useState([]);
    const [chartExpandState, setChartExpandState] =  useContext(ChartExpandStateContext);
    const [selectedDatapoint, setSelectedDatapoint] = useContext(SelectedDatapointContext);
    const [ticker, formType] = useContext(SearchBarContext);
    const [fromDate, toDate] = useContext(DateRangeContext);
    const [displayedCik, setDisplayedCik] = useContext(DisplayedCikContext);
    const [latestFilingData, setLatestFilingData] = useState([]);    
    const [accessToken, setAccessToken] = useState(null);
    
    // On initial render
    useEffect(
        () => {
            Auth.currentSession()
            .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
            .catch(error => {console.log(error);})
        },  
        []
    );

    // Latest filing API call
    useEffect(        
        () => {
            if (displayedCik !== null) {
                const requestPayload = {
                    "cik": displayedCik,
                };

                makePostRequest(API_URL+'/latest_filing', accessToken, requestPayload)
                .then(response => {       
                    // console.log("Latest filing response", response);
                    setLatestFilingData(response); 
                })
                .catch(error => {console.log(error);})
            }
        }, 
        [accessToken, displayedCik]
    ); 
    
    // Set the section expansion state
    useEffect(
        () => {
            if (sectionHeaders === null) {
                setSectionExpandState([]);
            } else {
                let tmp = sectionHeaders.map((x, i) => { return i === 0; });         
                tmp.push(false);      
                setSectionExpandState(tmp);
            }

        }, [sectionHeaders]
    )    

    const fd = new Date(fromDate).toLocaleDateString('en-US');    
    const td = new Date(toDate).toLocaleDateString('en-US');  

    if (data === undefined || data === null || 
        (data && data.length > 0 && data[0]['series'][0]['data']['isInDateRange'] === undefined) || /* This condition is needed because date filtering is run on a useEffect in DataPane, which means a render will happen before isInDataRange ever gets populated. */
        sectionHeaders === undefined || sectionHeaders === null) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <CircularProgress disableShrink={true}/>
            </Stack>
        )
    } else if (Array.isArray(data) && (data.length === 0 || data.every(ts => ts['shouldDisplay'] === false))) {   
        setSelectedDatapoint(null); 
        ampli.noQueryResult({ "Query Date Range": [fd, td], "Query Form Type": formType, "Query Symbol": ticker});    
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <Typography variant="h5" size="large"  sx={{mt: 1.5, color: "var(--brand-main)", fontWeight: '750'}}>No results found.</Typography>
                <Typography sx={{mt: 1.75, color: "var(--brand-text-secondary)", fontWeight: '750'}}>Our dataset is constantly expanding! </Typography>
                <Typography sx={{color: "var(--brand-text-secondary)"}} >Check back later or try adjusting your search parameters.</Typography>
            </Stack>
        )
    } 
    // When there is some data, and every the displayable data is masked.
    else if (Array.isArray(data) &&  data.every((ts,index) => (!ts['shouldDisplay'] || displayMask.has(index)))) { 
        // console.log(filterValue);
        ampli.noKeywordSearchResult({ "Query Date Range": [fd, td], "Query Form Type": formType, "Query Symbol": ticker, "Keyword": filterValue});       
        return (
            <Stack alignItems="center" justifyContent="center" height="100%" width="75%">
                <Typography variant="h5" size="large"  sx={{mt: 1.5, color: "var(--brand-main)", fontWeight: '750'}}>No results found.</Typography>                
                <List dense={true} sx={{"padding": "30px", listStyleType: 'disc'}}>        
                    <ListItem disableGutters sx={{fontWeight: '350', display: 'list-item'}}>
                        <ListItemText     
                            primaryTypographyProps={{fontSize: '1.1rem'}} 
                            primary="Shortening your filter to include only a specific word could help. Our keyword filter matches KPI names exactly."/>
                    </ListItem>  
                    <ListItem disableGutters sx={{fontWeight: '350', display: 'list-item'}}>
                        <ListItemText 
                            primaryTypographyProps={{fontSize: '1.1rem'}} 
                            primary="Did you know that you can filter KPIs based on their units? Simply enclose the units within parentheses." />
                    </ListItem>  
                    <ListItem disableGutters sx={{fontWeight: '350', display: 'list-item'}}>
                        <ListItemText 
                            primaryTypographyProps={{fontSize: '1.1rem'}} 
                            primary="Using different keywords with similar meaning may return more results." />
                    </ListItem>  
                    <ListItem disableGutters sx={{fontWeight: '350', display: 'list-item'}}>
                        <ListItemText 
                            primaryTypographyProps={{fontSize: '1.1rem'}} 
                            primary="Are you looking for a KPI that is usually reported in a table?  We're still working on this feature and plan on releasing it during the Beta Program." />
                    </ListItem>  
                    <ListItem disableGutters sx={{fontWeight: '350', display: 'list-item'}}>
                        <ListItemText 
                            primaryTypographyProps={{fontSize: '1.1rem'}}> 
                            Still can't find the KPI you're looking for?  We may be able to help.  Please e-mail us at <a href="mailto:support@revelata.com" target="_blank" rel="noreferrer">support@revelata.com</a>.
                        </ListItemText>
                    </ListItem>  

                </List>
            </Stack>
        )
    }     
    else {                
        // Determine common x-axis range
        const dates = data.map(
            (ts) => { 
                return ts['series'].filter(
                    (dp) => { return dp['data']['canDisplay'] && dp['data']['isInDateRange'] }
                    ).map((dp) => { return dp['data']['displayDatetime'] })
            }
        ).flat()
        dates.sort()  

        const minDate = datefnstz_toDate(dates[0] + "T00:00:00", { timeZone: 'America/New_York'}) ?? fromDate;
        const maxDate = datefnstz_toDate(dates[dates.length - 1] + "T00:00:00", { timeZone: 'America/New_York'}) ?? toDate;
        //console.log(minDate, maxDate);

        const flipChartExpandState = (idx, displayedTimeSeriesLength, hasDuplicate, containsLatestFiling) => {
            const tmp = [...chartExpandState];
            tmp[idx] = !tmp[idx];
            ampli.timeSeriesChartViewExpansion({
                "Query Symbol": ticker,
                "Chart Title": data[idx].chartTitle,
                "Description": data[idx].description,
                "Doc Type": data[idx].docType,
                "Form Type": data[idx].formType,
                "Hint": data[idx].hint,
                "Modifier": data[idx].modifier,
                "Score": data[idx].score.aggregated,
                "Section Idx": data[idx].sectionIdx,
                "Unit": data[idx].unit,
                "Chart Index": idx,
                "Series Length": displayedTimeSeriesLength,
                "Contains Latest Filing": containsLatestFiling,
                "Expanded": !tmp[idx],
                "Has Duplicate Time Series": hasDuplicate
            })            
            setChartExpandState(tmp);             
        }        

        const onClickSection = (sectionIdx, sectionName, expanded) => {                
            ampli.sectionClick({ "Section Name": sectionName, Expanded: expanded});             
            const tmp = [...sectionExpandState];                        
            tmp[sectionIdx] = !tmp[sectionIdx];
            setSectionExpandState(tmp);
            setSelectedDatapoint(null);
        }
        
        const sectionHeadersWithIndex = sectionHeaders.map((section, index) => (
            { index, name: section }
          ))
          .concat({ index: -1, name: "Other" });
        // console.log(sectionHeadersWithIndex);

        let sectionIdCountMap = {};
        data.forEach((item, idx) => {
            if (item.shouldDisplay && !displayMask.has(idx)) {
                sectionIdCountMap[item.sectionIdx] = (sectionIdCountMap[item.sectionIdx] || 0) + 1;                
            }
        });
        // console.log("sectionIdCountMap", sectionIdCountMap)

        const shouldUseSection = !hasOnlyMinusOneKey(sectionIdCountMap);
        const duplicateIndexes = findDuplicateCharts(data, displayMask);
        const firstDisplayedChart = data.findIndex((ts, idx) => !displayMask.has(idx) && ts['shouldDisplay']);
        
        return (
            <Stack spacing={0}>
                {
                    shouldUseSection && 
                    <Stack direction="row" alignItems="center" justifyContent="left" width="100%" sx={{p: 1, backgroundImage: "var(--mui-overlays-1)"}}>
                        <Typography sx={{fontSize: "1.2rem", fontWeight: 'bold'}}>Table of Contents</Typography> 
                        <Tooltip title={<h3>As an experimental feature, we've grouped our KPIs according to the section of the source filing in which they appear.</h3>}>
                            <Chip label=
                            {(<Stack direction="row" alignItems="center" justifyContent="left" sx={{color: "var(--brand-analogous)"}}>
                                <AutoAwesomeIcon fontSize="small" sx={{mr: "5px"}}/>
                                <Typography sx={{fontSize: "1.0rem", fontWeight: 'bold'}}>Experimental</Typography>
                                </Stack>
                            )}
                            size="small" sx={{color: "var(--brand-analogous)", ml: 1}} />
                        </Tooltip>
                    </Stack>
                }
                {
                    sectionHeadersWithIndex.map(
                        (section, sectionIdx) => {  // we use sectionIdx instead of section.index to index into sectionExpandState
                            return (
                                <Accordion 
                                    key={sectionIdx} 
                                    expanded={sectionExpandState[sectionIdx] ?? false} 
                                    onChange={(e,expanded) => {onClickSection(sectionIdx, section.name, expanded)}} 
                                    sx={{mr: 0}}
                                    disableGutters>
                                    {
                                        shouldUseSection && ( 
                                            <AccordionSummary sx={{mr: 0}} expandIcon={<ExpandMoreIcon sx={{color: "var(--brand-text-secondary)"}}/>}>
                                                <Stack direction="row" display="flex" justifyContent="space-between" width="100%">
                                                    <Typography sx={{pt: 1, m: 0, color: "var(--brand-text-secondary)", fontSize: "1.2rem", fontWeight: 'bold'}}>{section.name}</Typography>
                                                    <Typography sx={{p: 1, mr: 0, textAlign: "right", color: "var(--brand-text-secondary)"}}>({sectionIdCountMap[section.index] || 0} time series)</Typography>
                                                </Stack>
                                            </AccordionSummary>
                                        )
                                    }
                                    <Stack spacing={1}>
                                        {
                                            data.map(
                                                (ts, idx) => {                                                           
                                                    if (!displayMask.has(idx) && ts['shouldDisplay'] && ts.sectionIdx===section.index) {
                                                        return <TimeSeriesChartView 
                                                                    key={"tscv"+idx.toString()}                                                                     
                                                                    idx={idx}
                                                                    isFirstChart={firstDisplayedChart === idx}
                                                                    data={ts}
                                                                    minX={minDate.getTime()}
                                                                    maxX={maxDate.getTime()}
                                                                    expanded={chartExpandState[idx] ?? false}
                                                                    flipExpandedState={flipChartExpandState} 
                                                                    latestFilingData={latestFilingData}  
                                                                    hasDuplicate={duplicateIndexes.includes(idx)}
                                                                    ticker={ticker}                                                                                
                                                                />
                                                    }
                                                    return null;                            
                                                }
                                            )
                                        }
                                    </Stack>
                                </Accordion>
                            )
                        }
                    )
                }
            </Stack>
        )
    }
};

export default ChartList;