/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/revelata/deepkpi/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'deepkpi';

export const ApiKey: Record<Environment, string> = {
  deepkpi: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'web',
    versionId: '1316dff7-714e-4cb3-b8f8-aa8181798524'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface CreateAccountButtonClickProperties {
  Company: string;
  "Invitation Code": string;
}

export interface DataAuditProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Chart Index"?: number;
  "Chart Title"?: string;
  "Contains Latest Filing"?: boolean;
  Description?: string;
  "Display Datetime"?: string;
  "Display Value"?: string;
  "Doc Type"?: string;
  "Filing Date": string;
  "Form Type"?: string;
  "Has Duplicate Time Series"?: boolean;
  Hint?: string;
  Modifier?: string;
  /**
   * The caller requesting provenance data.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | app, docviewer |
   */
  "Provenance Caller": "app" | "docviewer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Provenance Details": string[];
  /**
   * Data API version
   */
  "Provenance Version"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol"?: string;
  "Reporting Period": string;
  Score?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Section Idx"?: number;
  Sentence: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Series Length"?: number;
  Unit?: string;
}

export interface DataExportProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Export Details"?: string[];
  /**
   * Data API version
   */
  "Export Version"?: string;
}

export interface KeywordSearchProperties {
  Keyword: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Items | 2 |
   * | Max Items | 2 |
   * | Item Type | string |
   *
   * @minItems 2
   * @maxItems 2
   */
  "Query Date Range": [string, string];
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 3 |
   * | Max Length | 10 |
   */
  "Query Form Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol": string;
}

export interface LoginButtonClickProperties {
  "Button Location"?: string;
}

export interface MergeDataExportProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Export Details"?: string[];
  /**
   * Data API version
   */
  "Export Version"?: string;
}

export interface NoKeywordSearchResultProperties {
  Keyword: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Items | 2 |
   * | Max Items | 2 |
   * | Item Type | string |
   *
   * @minItems 2
   * @maxItems 2
   */
  "Query Date Range": [string, string];
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 3 |
   * | Max Length | 10 |
   */
  "Query Form Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol": string;
}

export interface NoQueryResultProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Items | 2 |
   * | Max Items | 2 |
   * | Item Type | string |
   *
   * @minItems 2
   * @maxItems 2
   */
  "Query Date Range": [string, string];
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 3 |
   * | Max Length | 10 |
   */
  "Query Form Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol": string;
}

export interface QueryProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Items | 2 |
   * | Max Items | 2 |
   * | Item Type | string |
   *
   * @minItems 2
   * @maxItems 2
   */
  "Query Date Range": [string, string];
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 3 |
   * | Max Length | 10 |
   */
  "Query Form Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol": string;
}

export interface SectionClickProperties {
  Expanded: boolean;
  "Section Name": string;
}

export interface SignUpButtonClickProperties {
  "Button Location"?: string;
}

export interface SortProperties {
  "Sort Type": string;
}

export interface TimeSpanSelectionProperties {
  "Time Span": string;
}

export interface TimeSeriesChartViewExpansionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Chart Index": number;
  "Chart Title": string;
  "Contains Latest Filing": boolean;
  Description: string;
  "Doc Type": string;
  Expanded: boolean;
  "Form Type": string;
  "Has Duplicate Time Series": boolean;
  Hint: string;
  Modifier: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol": string;
  Score: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Section Idx": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Series Length": number;
  Unit: string;
}

export interface TimeSeriesChartViewSelectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Chart Index": number;
  "Chart Title": string;
  "Contains Latest Filing": boolean;
  Description: string;
  "Doc Type": string;
  Expanded: boolean;
  "Form Type": string;
  "Has Duplicate Time Series": boolean;
  Hint: string;
  Modifier: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol": string;
  Score: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Section Idx": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Series Length": number;
  Unit: string;
}

export interface AccountCreationFormDataProperties {
  Company: string;
  "Invitation Code": string;
}

export interface ExportRequestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Export Details"?: string[];
  /**
   * Data API version
   */
  "Export Version"?: string;
}

export interface ProvenanceRequestProperties {
  /**
   * The caller requesting provenance data.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | app, docviewer |
   */
  "Provenance Caller": "app" | "docviewer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "Provenance Details": string[];
  /**
   * Data API version
   */
  "Provenance Version"?: string;
}

export interface QueryParametersProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Items | 2 |
   * | Max Items | 2 |
   * | Item Type | string |
   *
   * @minItems 2
   * @maxItems 2
   */
  "Query Date Range": [string, string];
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 3 |
   * | Max Length | 10 |
   */
  "Query Form Type": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Min Length | 1 |
   * | Max Length | 10 |
   */
  "Query Symbol": string;
}

export interface TimeSeriesProperties {
  "Chart Title": string;
  Description: string;
  "Doc Type": string;
  "Form Type": string;
  Hint: string;
  Modifier: string;
  Score: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Section Idx": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Series Length": number;
  Unit: string;
}

export class CreateAccountButtonClick implements BaseEvent {
  event_type = 'Create Account Button Click';

  constructor(
    public event_properties: CreateAccountButtonClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataAudit implements BaseEvent {
  event_type = 'Data Audit';

  constructor(
    public event_properties: DataAuditProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DataExport implements BaseEvent {
  event_type = 'Data Export';

  constructor(
    public event_properties?: DataExportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class KeywordSearch implements BaseEvent {
  event_type = 'Keyword Search';

  constructor(
    public event_properties: KeywordSearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Login implements BaseEvent {
  event_type = 'Login';
}

export class LoginButtonClick implements BaseEvent {
  event_type = 'Login Button Click';

  constructor(
    public event_properties?: LoginButtonClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MergeDataExport implements BaseEvent {
  event_type = 'Merge Data Export';

  constructor(
    public event_properties?: MergeDataExportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NoKeywordSearchResult implements BaseEvent {
  event_type = 'No Keyword Search Result';

  constructor(
    public event_properties: NoKeywordSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NoQueryResult implements BaseEvent {
  event_type = 'No Query Result';

  constructor(
    public event_properties: NoQueryResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Query implements BaseEvent {
  event_type = 'Query';

  constructor(
    public event_properties: QueryProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SectionClick implements BaseEvent {
  event_type = 'Section Click';

  constructor(
    public event_properties: SectionClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUpButtonClick implements BaseEvent {
  event_type = 'Sign Up Button Click';

  constructor(
    public event_properties?: SignUpButtonClickProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Sort implements BaseEvent {
  event_type = 'Sort';

  constructor(
    public event_properties: SortProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TimeSpanSelection implements BaseEvent {
  event_type = 'Time Span Selection';

  constructor(
    public event_properties: TimeSpanSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TimeSeriesChartViewExpansion implements BaseEvent {
  event_type = 'TimeSeries Chart View Expansion';

  constructor(
    public event_properties: TimeSeriesChartViewExpansionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TimeSeriesChartViewSelection implements BaseEvent {
  event_type = 'TimeSeries Chart View Selection';

  constructor(
    public event_properties: TimeSeriesChartViewSelectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Create Account Button Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Create%20Account%20Button%20Click)
   *
   * Event triggered when a user clicks on the "Create Account" button
   *
   * @param properties The event's properties (e.g. Company)
   * @param options Amplitude event options.
   */
  createAccountButtonClick(
    properties: CreateAccountButtonClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateAccountButtonClick(properties), options);
  }

  /**
   * Data Audit
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Data%20Audit)
   *
   * This even tracks when and what data provenance is requested
   *
   * @param properties The event's properties (e.g. Chart Index)
   * @param options Amplitude event options.
   */
  dataAudit(
    properties: DataAuditProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataAudit(properties), options);
  }

  /**
   * Data Export
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Data%20Export)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Export Details)
   * @param options Amplitude event options.
   */
  dataExport(
    properties?: DataExportProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataExport(properties), options);
  }

  /**
   * Keyword Search
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Keyword%20Search)
   *
   * Event triggered when a user performs a keyword search within the Datapane
   *
   * @param properties The event's properties (e.g. Keyword)
   * @param options Amplitude event options.
   */
  keywordSearch(
    properties: KeywordSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new KeywordSearch(properties), options);
  }

  /**
   * Login
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Login)
   *
   * This event tracks when a user successfully logs into the platform
   *
   * @param options Amplitude event options.
   */
  login(
    options?: EventOptions,
  ) {
    return this.track(new Login(), options);
  }

  /**
   * Login Button Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Login%20Button%20Click)
   *
   * Event that captures when a user clicks on the login button
   *
   * @param properties The event's properties (e.g. Button Location)
   * @param options Amplitude event options.
   */
  loginButtonClick(
    properties?: LoginButtonClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginButtonClick(properties), options);
  }

  /**
   * Merge Data Export
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Merge%20Data%20Export)
   *
   * Event to track when data is exported using merge selection
   *
   * @param properties The event's properties (e.g. Export Details)
   * @param options Amplitude event options.
   */
  mergeDataExport(
    properties?: MergeDataExportProperties,
    options?: EventOptions,
  ) {
    return this.track(new MergeDataExport(properties), options);
  }

  /**
   * No Keyword Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/No%20Keyword%20Search%20Result)
   *
   * This event tracks when a user performs a search with no relevant results found
   *
   * @param properties The event's properties (e.g. Keyword)
   * @param options Amplitude event options.
   */
  noKeywordSearchResult(
    properties: NoKeywordSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new NoKeywordSearchResult(properties), options);
  }

  /**
   * No Query Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/No%20Query%20Result)
   *
   * This event tracks when a user performs a search query within the application and a user's search query returns no results.
   *
   * @param properties The event's properties (e.g. Query Date Range)
   * @param options Amplitude event options.
   */
  noQueryResult(
    properties: NoQueryResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new NoQueryResult(properties), options);
  }

  /**
   * Query
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Query)
   *
   * This event tracks when a user performs a search query within the application
   *
   * @param properties The event's properties (e.g. Query Date Range)
   * @param options Amplitude event options.
   */
  query(
    properties: QueryProperties,
    options?: EventOptions,
  ) {
    return this.track(new Query(properties), options);
  }

  /**
   * Section Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Section%20Click)
   *
   * This event tracks when a user clicks on a section of a document within Datapane (e.g., Part I, Part II and etc)
   *
   * @param properties The event's properties (e.g. Expanded)
   * @param options Amplitude event options.
   */
  sectionClick(
    properties: SectionClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SectionClick(properties), options);
  }

  /**
   * Sign Up Button Click
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Sign%20Up%20Button%20Click)
   *
   * Event that captures when a user clicks on the sign-up button
   *
   * @param properties The event's properties (e.g. Button Location)
   * @param options Amplitude event options.
   */
  signUpButtonClick(
    properties?: SignUpButtonClickProperties,
    options?: EventOptions,
  ) {
    return this.track(new SignUpButtonClick(properties), options);
  }

  /**
   * Sort
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Sort)
   *
   * This event tracks when a user sorts timeseries on data pane.
   *
   * @param properties The event's properties (e.g. Sort Type)
   * @param options Amplitude event options.
   */
  sort(
    properties: SortProperties,
    options?: EventOptions,
  ) {
    return this.track(new Sort(properties), options);
  }

  /**
   * Time Span Selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/Time%20Span%20Selection)
   *
   * Event that captures when a user selects a time span
   *
   * @param properties The event's properties (e.g. Time Span)
   * @param options Amplitude event options.
   */
  timeSpanSelection(
    properties: TimeSpanSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TimeSpanSelection(properties), options);
  }

  /**
   * TimeSeries Chart View Expansion
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/TimeSeries%20Chart%20View%20Expansion)
   *
   * Event tracking when a user expands a TimeSeries chart view
   *
   * @param properties The event's properties (e.g. Chart Index)
   * @param options Amplitude event options.
   */
  timeSeriesChartViewExpansion(
    properties: TimeSeriesChartViewExpansionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TimeSeriesChartViewExpansion(properties), options);
  }

  /**
   * TimeSeries Chart View Selection
   *
   * [View in Tracking Plan](https://data.amplitude.com/revelata/deepkpi/events/main/latest/TimeSeries%20Chart%20View%20Selection)
   *
   * Event tracking when a user selects a time series chart view
   *
   * @param properties The event's properties (e.g. Chart Index)
   * @param options Amplitude event options.
   */
  timeSeriesChartViewSelection(
    properties: TimeSeriesChartViewSelectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TimeSeriesChartViewSelection(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
